.footer {
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  padding: 0.5rem 1.5rem;
  height: 6rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #EDEFF2;
}

.footer-links, .footer-links:hover {
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  color: #7D8FB3;
  text-decoration: none;
  margin-right: 2rem;
}

.about-us {
  margin-left: auto;
}