.main-container {
    display: flex;
}

.content {
    padding: 2rem;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 12rem);
    position: relative;
    background-color: #f4f6f8;
}
.loading-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #999999;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-overlay {
    width: 100%;
    height: calc(100vh - 6.8rem);
    background-color: #999999;
    opacity: 0.7;
    position: absolute;
    display: none;
    top: 0;
    left: 0;
}

@media screen and (max-width: 599px) {
    .content.expanded .content-overlay {
        display: block;
    }

    .content.expanded {
        overflow: hidden;
        width: calc(100vw - 6.4rem);
        margin-left: 6.4rem;
    }

    .sidebar.open {
        position: fixed;
        z-index: 99;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
