.sidebar {
  width: 6.4rem;
  background-color: #ffffff !important;
  stroke: #DDECF4;
  //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 1px 3px rgba(0, 81, 175, 0.10));
  height: calc(100vh - 12rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  flex-shrink: 0; /*for outer flex*/
  transition: width 300ms ease-in-out;

  .inactive-logos {
    background: #EDEDF3;
    box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 55px;
    width: 50px;
    height: 50px;
    margin-left: 5px;
    margin-top: 10px;
  }
}

.top-sidebar {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;
  border-bottom: 2px solid #EDEFF2;
}

.bottom-sidebar {
  width: 100%;
}

.sidebar-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar-list-item {
  list-style: none;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar-link {
  display: flex;
  position: relative;
  width: 100%;
  text-decoration: none;
  height: 100%;
  align-items: center;
  color: #768AB1;
}

.sidebar-link:hover {
  background-color: #F7F7FB;
}

.sidebar-link.active {
  background-color: #F7F7FB;
}

.sidebar-link.active::after {
  content: "";
  background-color: #E0102A;
  position: absolute;
  right: 0;
  width: 0.3rem;
  height: 100%;
}

.sidebar-link > i {
  font-size: 2.2rem;
  margin-right: 1rem;
  color: #768AB1;
  padding-left: 21px;
}


.sidebar-link.active > i {
  color: #E0102A;
}

.sidebar .sidebar-link-text {
  width: 0;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.sidebar-link-text {
  font-size: 1.4rem;
  word-break: keep-all;
}

.sidebar.open {
  width: 220px;
}

.sidebar.open .sidebar-link > i {
  margin-right: 1.2rem;
}

.sidebar.open .sidebar-link {
  /*transition: justify-content 300ms ease-in-out;*/
}

.sidebar.open .sidebar-list-item .sidebar-link.active .sidebar-link-text {
  color: #202B40;
}


.sidebar.open .sidebar-link-text {
  opacity: 1;
  width: 100%;
}