.datepicker {
  border-radius: 0;
  padding: 25px;
  box-shadow: none;
  border: 1px solid $border-color;

  table {
    tr {
      th,
      td {
        border-radius: 0;
        width: 40px;
        height: 35px;
      }

      td {
        transition: all 0.2s ease-in-out;

        span {
          border-radius: 0;
        }
      }
    }
  }
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus {
  color: $default-white;
  background-color: $default-primary;
  border-color: transparent;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: $default-primary;
  color: #fff;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: $default-primary;
  color: #fff;
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: $default-primary;
  color: #fff;
  cursor: pointer;
}

.datepicker-inline {
  width: 330px;
}

.daterangepicker {
  border-radius: 0;
  padding: 30px;
  box-shadow: none;
  border: 1px solid $border-color;

  .input-mini {
    border-radius: 0;
    margin-bottom: 20px;
    height: 40px;
    padding: 0 6px 0 35px;

    &.active {
      border-radius: 0;
      border-color: lighten($default-info, 20%);
    }
  }

  .daterangepicker_input {
    i {
      position: absolute;
      left: 10px;
      top: 13px;
    }
  }

  td,
  th {
    border-radius: 0;
    width: 40px;
    height: 35px;

    &.available {
      &:hover {
        background: $default-primary;
        color: #fff;
      }
    }
  }

  td {
    &.in-range {
      background-color: transparent;
      color: $default-primary;
    }

    &.active {
      background-color: $default-primary;
      border-color: transparent;
      color: #fff;

      &:hover {
        background-color: $default-primary;
        border-color: transparent;
        color: #fff;
      }
    }

    &.start-date {
      border-radius: 0;

      &.end-date {
        border-radius: 0;
      }
    }

    &.end-date {
      border-radius: 0;
    }
  }

  select {
    &.hourselect,
    &.minuteselect,
    &.secondselect,
    &.ampmselect {
      border: 1px solid $border-color;
      min-height: 30px;
    }
  }

  .calendar-time {
    i {
      top: 8px;
      left: 35px;
    }
  }

  @include from($breakpoint-sm) {
    .calendar {
      margin-right: 20px !important;
    }
  }
}

