#sandbox-certi-instructions {
  color: #495472;
  line-height: 28px;
  font-weight: 500;

  .quest {
    padding: 6rem;
    //min-height: 1000px;
  }

  p, li {
    line-height: 3;
  }

  .instruction-header {
    font-size: 1.4rem;
  }

  .instruction-sub-header {
    margin-top: 4rem;
    margin-bottom: 0;
  }

  .instruction-search {
    font-weight: 600;
    line-height: 28px;
    background: #EDEDF3;
    padding: 2.5rem;
  }

  .submit-instructions-btn {
    background-color: #072233;
    padding: 1.5rem;
    width: 100%;
    border-radius: 8px;
  }
}

.sandbox-instructions-mapping-files-wrapper {
  display: flex;
  margin-bottom: 10rem;
  .files-wrapper {
    .fa.file-fa {
      font-size: 3rem;
      color: #ED1B2F;
      margin: 2.5rem 3rem 2.5rem 0;
    }

    .file-wrapper-name {
      color: #072233;
      font-size: 1.6rem;
      margin-top: 2.5rem;
    }
    .files-details-header {
      font-size: 1.6rem;
      color: #768AB1;
    }
    .files-details-desc {
      font-size: 1.2rem;
      color: #768AB1;
    }
    .files-details-desc {
      font-weight: 400;
      font-size: 1.2rem;
      a, a:hover {
        color: #768AB1;
        text-decoration: none;
        margin-left: 0.5rem;
      }
      .fa {
        color: #768AB1;
      }
      .file-url {
        text-decoration: underline;
      }
    }
    .files-btn-div {
      float: right;
    }
    .files-btn-div:hover {
      .fa {
        color: #FFFFFF;
      }
    }
    .files-btn {
      height: auto;
      width: auto;
      padding: 1rem 2rem;
      margin-top: 2rem !important;
      background-color: #E5E7EC;
      color: #072233;
      font-weight: 700;
      border-radius: 30px;
      .fa {
        color: #072233;
        font-size: 1.5rem;
        margin: 0 1rem 0 0;
      }
    }
  }
  .mapping-files-wrapper {
    width: 26%;
    border-right: 2px solid #EDEFF2;
    padding-left: 1rem;
  }
  .dev-docs-wrapper {
    width: 37%;
    border-right: 2px solid #EDEFF2;
    padding-left: 3rem;
  }
  .token-wrapper {
    width: 37%;
    padding-left: 3rem;
    .token-input-div {
      position: relative;
      input {
        background-color: #FFFFFF;
        padding: 1rem 6rem 1rem 1rem;
        color: #768AB1;
      }
      .token-copy-text {
        .fa {
          margin-right: 0.5rem;
        }
        cursor: pointer;
        position: absolute;
        color: #768AB1;
        font-size: 1.2rem;
        top: 5px;
        right: 5px;
      }
    }
  }
}
