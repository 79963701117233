// ---------------------------------------------------------
// @Pseudo Elements
// ---------------------------------------------------------

.no-after::after {
  display: none !important;
}

.no-before::before {
  display: none !important;
}
