.auth {
    width: 100vw;
    height: 100 dvh;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url("../../assets/img/login-background.svg");
    background-repeat: repeat-x;
}

.auth-container {
    width: 47.5rem;
    max-width: 90%;
    margin: 12rem auto;
    text-align: center;
}

.login-title {
    color: #ffffff;
    line-height: 2rem;
}

.login-form {
    background-color: #ffffff;
    margin-top: 6rem;
    /* height: 39rem; */
    border-radius: 2.4rem;
    box-shadow: 0 4px 4px rgba(123, 154, 171, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 46px;
    gap: 24px;
}

.login-form form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.login-form form label {
    color: #5d6270;
}

.login-form input:not([type="checkbox"]) {
    height: 4rem;
    font-size: 1.2rem;
    padding: 12px;
    border-radius: 8px;
}

@media screen and (min-width: 1540px) {
    .auth {
        background-size: 100% auto;
    }
}

@media screen and (max-height: 739px) {
    .auth {
        display: flex;
        align-items: center;
    }

    .login-form {
        margin-top: 1rem;
        max-height: 60 dvh;
        padding: 2rem;
        gap: 1rem;
    }

    .login-form input:not([type="checkbox"]) {
        height: 2.5rem;
        font-size: 1.2rem;
        padding: 12px;
        border-radius: 8px;
    }

    .auth-container {
        margin: 1rem auto;
        width: 60vw;
    }
}
