.documentation-page-wrapper {
  padding: 4rem;
  color: #7b899e;
  font-size: 1.2rem;

  .btn {
    padding: 1rem 2rem;
    border-radius: 20px;
    width: 175px;
    font-size: 1.2rem;
  }

  .documentation-page-header {
    display: flex;
    justify-content: space-between;

    .documentation-page-header-text {
      font-weight: 700;
      font-size: 2.8rem;
      color: #170F49;
    }

    .documentation-page-header-desc {
      color: #7B899E;
    }

    .documentation-page-buttons {
      .go-back-btn {
        background-color: #072233;
      }

      .download-all-btn {
        background-color: #E5E7EC;
        color: #072233;

        .fa {
          font-size: 1.4rem;
          margin-right: 1rem !important;
        }
      }
    }
  }

  .client-account-details-wrapper {
    display: flex;
    margin-top: 2rem;
    background-color: #FFFFFF;
    padding: 4rem 3rem 5rem 3rem;
    color: #072233;
    border-radius: 5px;
    border-top: 4px solid #072233;

    .client-account-details-desc {
      flex-basis: 78%;
      display: flex;

      .fa {
        font-size: 5rem;
        margin-right: 2.5rem;
      }

      .client-account-details-header-text {
        font-size: 2.4rem;
      }
      .client-account-details-header-sub-text {
        color: #7b899e;
      }
    }

    .btn {
      background-color: #FFFFFF;
      color: #072233;
      width: 235px;
      padding: 1.2rem 3rem;

      .fa {
        margin-left: 1rem;
        font-size: 1.5rem;
      }
    }
  }

  .doc-file-wrapper {
    color: #072233;
    background-color: #FFFFFF;
    padding: 3rem 2rem;
    margin-top: 1.5rem;
    border-radius: 10px;
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .doc-file-date {
      font-size: 1rem;
      color: #828282;
    }

    .doc-file-header {
      font-weight: 700;
      margin-top: 1.5rem;
      font-size: 1.4rem;
      color: #4F4F4F;
    }

    .doc-file-text {
      margin-top: 3rem;
      margin-bottom: 3rem;
      min-height: 3.6rem;
      color: #7B899E;
    }
    .download-btn {
      width: 100%;
      border-radius: 5px;
      background-color: #072233;
      font-weight: 700;
      margin-bottom: 2rem;
      display: block;
      padding: 1rem;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

#client-account-details.tab-content {
  padding: 0;
  .tab-content-details {
    padding: 2rem 4rem;
    .client-account-details-header {
      color: #072233;
      font-size: 2rem;
      font-weight: 700;
    }
    .tab-content-details-text {
      color: #7b899e;
    }
  }

  .client-account-quest {
    color: #072233;
  }

  .client-details-questions-wrapper {
    background-color: #F6F6F9;
    .grey {
      padding: 4rem;
      .question-text {
        margin-left: 2rem;
        font-weight: 500;
      }
      input {
        background-color: #EDEDF3;
        border: none;
        border-bottom: 1px solid #DBE1EA;
        margin-top: 2rem;
        margin-bottom: 4rem;
        color: #989FB4;
      }
      input:focus {
        box-shadow: none;
      }
    }
  }

  .client-account-details-submit-button {
    margin-top: 2.5rem;
    .submit-btn {
      width: 100%;
      background-color: #072233;
      padding: 1.5rem;
    }
  }
}