@import "src/theme";

.dash-card {
  border-radius: 2.4rem;
  padding: 3rem;
  width: 100%;
  color: $grey-f1;

  .tab-d {
    padding: 1rem 1.5rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .tab-d.select {
    color: #495472;
    background: #E0E1E3;
  }

  p {
    margin: 1rem 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }

  .fa-arrow-circle-down {
    color: #ED1B2F;
  }

  .MuiPaper-root {
    box-shadow: none;
  }
}

.dashboard-g-card {
  max-height: 35rem !important;
  padding-bottom: 1.5rem !important;

  .add-team-member-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #4766AC;
  }

  .add-team-member-icon {
    font-size: 2.5rem;
    text-align: center;
  }

  .add-team-member-label {
    font-weight: 600;
    font-size: 22px;
    line-height: 122.9%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  .add-team-member-desc {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
  }

  .add-team-member-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 122.9%;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
  }
}

.view-team-mate-details {
  color: #495472;
  background: #E0E1E3;
  border-radius: 4px;
  padding: 15px 25px;
  cursor: pointer;
}

.dashboard-welcome-modal {
  width: 550px !important;
  max-width: none;
  margin-top: 10% !important;

  .modal-content {
    border-radius: 32px;
  }

  .modal-body {
    padding: 0 0 20px 0;
  }

  .dashboard-popup-image {
    height: 300px;
    background-size: cover;
  }

  .dashboard-modal-complete-certi {
    background-image: url("../../assets/img/ONBOARD1.png");
  }

  .dashboard-modal-add-team {
    background-image: url("../../assets/img/ONBOARD2.png");
  }

  .dashboard-modal-verify-metadata {
    background-image: url("../../assets/img/ONBOARD3.png");
  }

  .dashboard-welcome-modal-desc {
    padding: var(--bs-modal-padding);

    .dashboard-welcome-modal-header {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #172635;
      padding-top: 20px;
    }

    .dashboard-welcome-modal-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #8D8B8B;
      max-width: 335px;
      margin: auto;
    }

    .dashboard-welcome-modal-footer {
      padding: 10px 20px;

      .dashboard-welcome-modal-footer-skip {
        float: left;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        color: #3F3F3F;
        cursor: pointer;
      }

      .dashboard-welcome-modal-footer-next {
        float: right;
        padding: 5px;
        background: #EE293C;
        box-shadow: 0 18px 31px rgba(255, 145, 73, 0.25);
        border-radius: 28px;
        width: 85px;
        text-align: center;
        font-size: 20px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }

  }
}

.sandbox-cred-footer-btn {
  background-color: #072233 !important;
  width: 100%;
  margin-top: 20px;
  padding: 10px !important;
}

.team-member-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .btn {
    //width: 200px;
    border-radius: 4px;
    text-align: center;
    padding: 10px;
    flex-grow: 1;
    margin: 10px;
  }

  .disable-members-btn {
    background: #072233;
  }

  .view-team-mate-details {
    background: #EDEDF3;
    color: #495472;
  }
}

.active-step-label {
  font-weight: 700;
}

.dashboard-stepper {
  margin-top: 3rem;
  margin-left: 5.5rem;

  .welcome-step {
    max-width: 600px;
    margin: 10rem auto;
    text-align: center;

    img {
      margin-bottom: 2rem;
    }

    .stepper-header {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: #072233;
      margin-bottom: 2rem;
    }

    .stepper-desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 36px;
      color: #65738E;
    }

    .btn {
      font-size: 12px;
    }
  }

  .dashboard-stepper-step-count {
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 25px;
    color: #7D8FB3;
    margin-bottom: 5rem;
  }

  .dashboard-stepper-step-count-label {
    font-weight: normal;
  }

  .btn {
    width: 150px;
    height: 52px;
    background: #ED1B2F;
    border-radius: 55px;
    border: 0;
  }

  .secondary-btn {
    background: #E0E1E3;
    border: 1px solid #ED1B2F;
    color: #ED1B2F;
    margin-left: 10px;
  }
}

.stepper-main-wrapper {
  display: flex;

  .stepper-desc-wrapper {
    max-width: 600px;
    margin-left: 20px;

    .stepper-header {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: #072233;
    }

    .stepper-desc-main {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 36px;
      color: #65738E;
    }

    .stepper-desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 36px;
      color: #65738E;

      p {
        margin-bottom: 0;
        line-height: 3.6rem;
      }

      .stepper-desc-sub-header {
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 2.5rem;
      }

      .complete-screen-stepper-desc {
        margin-bottom: 2rem;
      }
    }
  }

  .dashboard-files-wrapper {
    width: 600px;
    margin-left: auto;
    margin-right: 4rem;
  }
}

.dashboard-mapping-files {
  .mapping-files-header {
    color: #5B6C8E;
    font-size: 1.4rem;
  }
  .mapping-file-wrapper-div {
    height: 75px;
    background-color: #FFFFFF;
    border-radius: 5px;
    display: flex;

    .fa {
      font-size: 3rem;
      color: #768AB1;
      margin: 2.5rem 3rem;
    }

    .mapping-file-wrapper-name {
      color: #072233;
      font-size: 1.6rem;
      font-weight: 700;
      margin-top: 2.5rem;
    }
  }
  .mapping-file-wrapper-div:hover {
    .fa.mapping-file-fa {
      color: #ED1B2F;
    }
  }
  .mapping-files-gap {
    height: 1.5rem;
  }
  .mapping-files-btn-div {
    margin-left: auto;
    margin-right: 2rem;
  }
  .mapping-files-btn-div:hover {
    .fa {
      color: #FFFFFF;
    }
  }
  .mapping-files-btn {
    height: auto;
    width: auto;
    padding: 1rem 4rem;
    margin-top: 2rem !important;
    background-color: #E5E7EC;
    color: #072233;
    font-weight: 700;
    .fa {
      color: #072233;
      font-size: 1.5rem;
      margin: 0 1rem 0 0;
    }
  }

  .mapping-files-details-div {
    padding: 3rem 2.5rem 4rem 3rem;
    color: #768AB1;
    background-color: #EDEFF2;
    border-radius: 5px;
    .mapping-files-details-header {
      font-weight: 700;
      font-size: 1.6rem;
    }
    .mapping-files-details-desc {
      font-weight: 400;
      font-size: 1.2rem;
      a, a:hover {
        color: #768AB1;
        text-decoration: none;
        margin-left: 0.5rem;
      }
    }
    .file-url {
      text-decoration: underline;
    }
    .token-input-div {
      position: relative;
      input {
        background-color: #FFFFFF;
        padding: 1rem 6rem 1rem 1rem;
        color: #768AB1;
      }
      .token-copy-text {
        .fa {
          margin-right: 0.5rem;
        }
        cursor: pointer;
        position: absolute;
        color: #768AB1;
        font-size: 1.2rem;
        top: 10px;
        right: 5px;
      }
    }
  }

}

.api-client-under-review-wrapper {
  background-color: #22B573;
  height: 20rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  display: flex;
  color: #FFFFFF;
  align-items: center;
  .under-review-img {
    flex: 0 0 150px;
    font-size: 100px;
    img {
      height: 150px;
    }
  }
  .under-review-desc {
    flex: 1;
    .under-review-desc-header {
      font-size: 3.2rem;
      text-shadow: 0 2.8625807762145996px 2.8625807762145996px 0 #00000040;
      font-weight: 600;
    }
    .under-review-desc-sub {
      font-size: 1.4rem;
      font-weight: 300;
    }
  }
}
