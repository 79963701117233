//Overrides
$primary: #E0102A;
$grey-f1: #768AB1;
$grey-f2: #495472;
$grey-f3: #EDEDF3;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

@import '~react-quill/dist/quill.snow.css';

.bg-grey {
  background: #E0E1E3;
  border-radius: 1.5rem;
  padding: 1.5rem;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  color: $grey-f2;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  color: $grey-f2;
}

h5 {
  font-size: 18px;
  font-weight: 600;
}

h6 {
  font-size: 1.2rem;
  font-weight: 600;
  color: $grey-f2;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.form-control, .form-select {
  font-size: 12px;
}

.empty {
  background-color: $grey-f3;
  height: 22rem;
  justify-content: center;
  align-items: center;

  img {
    width: 5rem;
  }
}