html {
    font-size: 62.5% !important;
    overflow: hidden;
}

body {
    margin: 0;
    background-color: #E0E1E3 !important;
    font-size: 1.4rem;
}

p {
    word-wrap: break-word;
}

*, *::before, *::after {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-10 {
    font-size: 10px !important;
}

.font-11 {
    font-size: 11px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-bold {
    font-weight: bold;
}

.link {
    color: #768AB1;
    cursor: pointer;
}

.link:hover {
    color: #E0102A;
}

.full-btn {
    height: 3.6rem;
    border-radius: 8px;
}

.clear {
    clear: both;
}

/*
  Quill Input Editor
*/
.input-editor {
    border: 2px solid rgba(180, 187, 198, 0.5);
    border-radius: 9px;
}

.input-editor .ql-toolbar {
    border: none;
    border-bottom: 2px solid rgba(180, 187, 198, 0.5);
}

.input-editor .ql-container {
    border: none;
    padding: 10px 2px;
}

.input-editor .ql-container .ql-editor {
    color: #9E9EA8;
}

.modal-footer .btn {
    background: #EDEDF3;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #261F2B;
    padding: 1rem 1.5rem;
    min-width: 150px;
}

.add-team-member-footer {
    justify-content: center !important;
}

.add-team-invite-btn {
    background: #E0102A !important;
    color: #FFFFFF !important;
}

/*
Tooltip
*/
.ht-tooltip .tooltip.show {
    opacity: 1 !important;
}

.ht-tooltip .tooltip-inner {
    background-color: #FFFFFF !important;
    max-width: none !important;
    width: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #263E4C;
    padding: 20px !important;
    text-align: center;
}

.ht-tooltip .tooltip-header {
    font-weight: 700;
    font-size: 16px;
}

.progress-bar.bg-success {
    background-color: #92E251 !important;
}
