.tab-content {
  padding: 2rem;
  background-color: #ffffff;
  transition: display 300ms ease-in-out;
  border-radius: 10px;

  .header-color {
    color: #495472;
  }

  .questionnaire-date-details {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    color: #7B899E;
  }

  .questionnaire-name {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: flex-start;
    .bookmark-wrapper {
      width: 3rem;
      height: 4rem;
      background-color: #ED1B2F;
      margin-top: 0.7rem;
      margin-right: 2rem;
      .bookmark {
        clip-path: polygon(0 0, 0 100%, 100% 100%, 50% 75%, 0 100%);
        width: 100%;
        height: 101%;
        background: #FFFFFF;
      }
    }
  }

  .questionnaire-desc {
    color: #7B899E;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .date-name {
    font-size: 1.2rem;
    color: #495472;
    font-weight: 500;
    margin-right: 5px;
  }

  .grey {
    background-color: #EDEDF3;
    padding: 2rem 1.5rem;
    border-radius: 10px;

    .quest {
      background-color: #ffffff;
      padding: 2.5rem 2rem 10rem 3rem;
      border-radius: 8px;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
      //height: calc(100vh - 44rem);
      min-height: 35rem;
      //overflow-y: auto;

      .cert-q {
        font-weight: 700;
        font-size: 16px;

        .q-sample-answer {
          font-weight: 400;
          font-size: 14px;
          margin-top: 10px;
        }
      }

      .fgrp {
        margin: 2rem 0;
      }

    }
  }

  .question-footer {
    margin-top: 2rem;

    .submit-api-questionnaire {
      width: 100%;
      background-color: #072233;
      padding: 1rem;
      font-size: 1.4rem;
    }
  }

  .questionnaire-questions-wrapper {
    display: flex;
    .questionnaire-group-wrapper {
      min-width: 380px;
      max-width: 400px;
    }
    .questionnaire-group-questions-wrapper {
      flex-grow: 1;
      min-width: 650px;
    }
  }
}

.questionnaire-empty-div {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  div[aria-label="oval-loading"] {
    margin-top: 2rem;
  }
}
