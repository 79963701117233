.q-carousel {
  height: 100%;
  //max-height: 50rem;
  //overflow-y: auto;
  padding-right: 1rem;

  .ques-grouping-desc {
    color: #495472;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .question-list-item {
    background: #FFFFFF;
    box-shadow: 0 2px 16px rgba(153, 155, 168, 0.12);
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    min-height: 65px;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;

    .fa {
      color: #E9E9F3;
      font-size: 20px;
      margin-right: 1.5rem;
    }

    p {
      margin: 0;
    }
  }

  .active-question-list-item {
    background: #E9E9F3;

    .fa {
      color: #FFFFFF;
    }
  }

  .question-type-header {
    background: #263E4C;
    box-shadow: 0 2px 16px rgba(153, 155, 168, 0.12);
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    padding: 2rem;
    margin: 1rem;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    opacity: 0.9;

    .questions-count {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
    }
  }

  .ques-grouping-list-item {
    height: 120px;
    background-color: #F5F5F8;
    border: 1px solid #F5F5F8;
    display: flex;
    justify-content: space-between;
    color: #495472;
    padding: 1.5rem 2.5rem;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 5px;

    .ques-grouping-header {
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 0.5rem;
      line-height: 1.4;
      margin-top: 1rem;
    }

    .ques-grouping-progress {
      font-size: 1.2rem;
    }

    .ques-grouping-img {
      width: 34px;
      height: 34px;
      text-align: center;
      background-color: #F5F5F8;
      min-width: 34px;

      .fa {
        visibility: hidden;
      }
    }

    .active-ques-grouping-img {
      background: #F5F5F8;
      box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
      border-radius: 17px;
      margin-top: 3rem;

      .fa-visible {
        visibility: visible;
        font-size: 1.5rem;
        margin-top: 10px;
        color: #FFFFFF;
      }
      .fa.fa-exclamation-circle {
        visibility: hidden;
      }
    }

  }

  .error-scenario-group {
    border: 1px solid #DBCE5A;
    .fa.fa-exclamation-circle {
      visibility: visible;
      color: #DBCE5A;
      font-size: 3.3rem;
      margin-top: 3rem;
    }
  }

  .success-scenario-group {
    border: 1px solid #37D087;
    .fa.fa-check-circle {
      visibility: visible;
      color: #37D087;
      font-size: 3.3rem;
      margin-top: 3rem;
    }
  }

  .active-ques-grouping-img {
    .fa.fa-exclamation-circle {
      visibility: hidden;
    }
    .fa.fa-check-circle {
      visibility: hidden;
    }
  }

  .active-ques-grouping-list-item {
    background-color: #FFFFFF;
    border: 1px solid #E1E1E9;
  }
}

.sandbox-ques-grouping {
  .ques-grouping-desc {
    padding-top: 1.5rem;
  }
  .ques-grouping-header {
    font-size: 1.2rem !important;
    margin-bottom: 2rem !important;
  }
}
