@import "../../../theme";

.scenario-box {
  background-color: #F5F5F8;
  color: #7B899E;
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 4px;
  height: 5rem;
  padding: 1.5rem;
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-width: 15rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  .fa {
    visibility: hidden;
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.error-scenario {
  background-color: #DBCE5A;
  color: #FFFFFF;
  .fa-exclamation-circle {
    visibility: visible;
  }
}

.success-scenario {
  background-color: #37D087;
  color: #FFFFFF;
  .fa-check-circle {
    visibility: visible;
  }
}

.scenario-box-active {
  background-color: #072233;
  //background-color: #ED1B2F;
  color: #FFFFFF;
  .fa {
    visibility: hidden;
  }
}

.scenario-detail {
  //background-image: url("../../../assets/img/banner6.svg");
  //background-size: cover;
  //background-repeat: no-repeat;
  //background: linear-gradient(90deg, #0F4567 0%, #0C2637 100%);
  background-color: #F5F5F8;
  border-radius: 5px;
  color: #495472;
  padding: 3rem 4rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  min-height: 140px;
  border: 1px solid #E1E1E9;

  .scenario-desc-header {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    font-weight: 400;
    display: flex;
    .scenario-desc-header-right {
      flex-grow: 1;
      border-bottom: 1.5px solid #E1E1E9;
      margin-left: 2rem;
    }
  }

  .scenario-detail-text {
    font-size: 1.8rem;
  }

  .scenario-detail-text-sub {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    font-weight: 400;
  }
}

.scenario-detail-light {
  background: #EDEDF3;
  color: #354B59;
}
