.question-detail-input-multi-option {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.question-detail-input-single-option {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.quest {
  input[type='checkbox'], input[type='radio'] {
    width: 15px;
    height: 15px;
  }
}

.question-footer-text {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 28px;
  text-align: right;
  color: #1A1A1A;
  margin-top: 15px;
}

.grouping-question-box {
  display: flex;
  font-size: 1.2rem;
  margin: 1rem 0.5rem;
  padding: 1rem 0.5rem;

  .grouping-question-ques-name {
    flex-basis: 55%;
    padding-top: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    display: flex;
    color: #494949;
    min-height: 3.5rem;

    div:first-child {
      margin-right: 0.5rem;
    }
  }

  .grouped-multi-option-ques-option-item-header {
    font-weight: 700;
  }

  .grouped-multi-option-ques-option-item-header:first-letter {
    text-transform: uppercase;
  }

  .grouping-question-ques-option-item {
    flex: 1;
    font-size: 1.2rem;
    padding-top: 0.7rem;

    .grouped-multi-option-ques-option-item-select {
      width: 45px;
      height: 25px;
      border: 0.5px solid #474747;
      border-radius: 5px;
      cursor: pointer;
    }

    .grouped-multi-option-ques-option-item-select-checked {
      border: none;
      background-color: #37D087;
    }
  }

  .disabled-grouped-option {
    cursor: not-allowed !important;
  }

  .disabled-grouping-question-ques-option-item {
    cursor: not-allowed !important;
  }

  .single-choice-multi-option-ques-option-item {
    background-color: #F5F5F8;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    color: #495472;
    cursor: pointer;
  }

  .selected-single-choice-multi-option-ques-option-item {
    background-color: #37D087;
    color: #FFFFFF;
  }

  .selected-single-choice-multi-option-ques-option-item-no {
    background: #2D3134;
    color: #FFFFFF;
  }

  .grouping-question-ques-option-item-dropdown-selected {
    .grouping-question-ques-select-dropdown__control {
      border: 1.5px solid #0ac578;
    }
  }
}

.grey {
  .grouping-name {
    .grouping-header {
      font-weight: 700;
      font-size: 2rem;
    }

    .grouping-progress {
      font-size: 1.2rem;
      color: #505050;
    }
  }
}

.single-choice-multi-option-ques-wrapper {
  padding: 0.5rem;

  .single-choice-question-ques-name {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: #495472;
    font-weight: 700;
  }
  .single-choice-question-box-options {
    position: relative;
    display: flex;
    margin-bottom: 2.5rem;
    .fa {
      visibility: hidden;
      position: absolute;
      top: 2.5rem;
      right: 1.5rem;
      font-size: 1.5rem;
    }
  }
  .single-choice-question-box-options-submitted-wrapper {
    border: 2px solid #37D087;
    padding: 1rem;
    border-radius: 4px;

    .fa.fa-check-circle {
      visibility: visible;
      color: #37D087;
    }
  }
  .single-choice-question-box-options-error-wrapper {
    border: 2px solid #DBCE5A;
    padding: 1rem;
    border-radius: 4px;

    .fa.fa-exclamation-circle {
      visibility: visible;
      color: #DBCE5A;
    }
  }

  .grouping-question-ques-option-item {
    flex: 1;
    font-size: 1.2rem;
    padding-top: 0.7rem;
    max-width: 20rem;

    .grouped-multi-option-ques-option-item-select {
      width: 45px;
      height: 25px;
      border: 0.5px solid #474747;
      border-radius: 5px;
      cursor: pointer;
    }

    .grouped-multi-option-ques-option-item-select-checked {
      border: none;
      background-color: #37D087;
    }
  }

  .disabled-grouped-option {
    cursor: not-allowed !important;
  }

  .disabled-grouping-question-ques-option-item {
    cursor: not-allowed !important;
  }

  .single-choice-multi-option-ques-option-item {
    background-color: #F5F5F8;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    color: #495472;
    cursor: pointer;
  }

  .selected-single-choice-multi-option-ques-option-item {
    background-color: #37D087;
    color: #FFFFFF;
  }

  .selected-single-choice-multi-option-ques-option-item-no {
    background: #2D3134;
    color: #FFFFFF;
  }

  .grouping-question-ques-option-item-dropdown-selected {
    .grouping-question-ques-select-dropdown__control {
      border: 1.5px solid #0ac578;
    }
  }
}

.grouped-multi-option-ques-box-header {
  border-bottom: 1px solid #C0C0C0;
  color: #495472;
}

.grouping-border {
  margin-top: 3rem;
}

.api-client-grouping-border {
  border-bottom: 1px solid #C0C0C0;
}

.group-input-text-question {
  margin-bottom: 2.5rem;
  padding: 0.5rem;

  .group-gues-input-wrapper {
    position: relative;

    input {
      border: 1px solid #DBE1EA;
      background: #F5F5F8;
      padding: 2rem 2.5rem;
      color: #495472;
    }

    input:focus {
      border: 2px solid #DBE1EA !important;
      box-shadow: none !important;
    }

    .fa {
      visibility: hidden;
      position: absolute;
      top: 2.5rem;
      right: 1.5rem;
      font-size: 1.5rem;
    }
  }

  .group-gues-input-submitted-wrapper {
    input {
      border: 2px solid #37D087;
      cursor: not-allowed;
    }

    .fa.fa-check-circle {
      visibility: visible;
      color: #37D087;
    }
  }

  .group-gues-input-error-wrapper {
    input {
      border: 2px solid #DBCE5A;
    }

    .fa.fa-exclamation-circle {
      visibility: visible;
      color: #DBCE5A;
    }
  }

  .input-text-label {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    color: #495472;

    .input-text-label {
      font-weight: 700;
    }

    .input-text-placeholder {
      margin-left: 1.5rem;
    }

    .input-sample-answer {
      font-weight: 700;
      margin-left: 1.5rem;

    }
  }

}

