html,
html a,
body {
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 14px;
}

body {
  font-family: $font-primary;
  color: $default-text-color;
  line-height: 1.5;
  letter-spacing: 0.2px;
  overflow-x: hidden;
}

// scrollbar styles

::-webkit-scrollbar {
  visibility: hidden;
  -webkit-appearance: none;
  height: 10px;
  background-color: transparent;
  width: 12px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: lightgray;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  letter-spacing: 0.5px;
  line-height: 1.5;

  a {
    font-family: $font-secondary;
  }

  small {
    font-weight: 300;
    color: lighten($default-dark, 5%);
  }
}

p {
  font-family: $font-primary;
  line-height: 1.9;
}

.lead {
  font-size: 18px;
}

ul {
  margin-bottom: 0;
}

a {
  color: $default-info;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    text-decoration: none;
    color: darken($default-info, 10%);
  }

  &:focus {
    outline: none;
  }

  &.text-gray {
    &:hover,
    &:focus,
    &.active {
      color: $default-dark !important;
    }
  }
}

:focus {
  outline: none;
}

hr {
  border-top: 1px solid $border-color;
}
