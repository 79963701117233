@import "src/assets/static/styles/spec/settings/fonts";


.MuiStepLabel-label, .MuiTypography-root {
  font-size: 16px !important;
  font-family: $font-primary !important;
}

.MuiSvgIcon-root {
  font-size: 30px !important;
  border: 5px solid #EDEDF3;
  border-radius: 15px !important;
  color: #CBD1E1 !important;
}

.MuiStepIcon-text {
  font-size: 14px !important;
}

.MuiSvgIcon-root.Mui-completed {
  color: #2CC858 !important;
  //border: 5px solid #2CC858;
  border: none;
}

.MuiStepLabel-root {
  padding: 0 !important;
}

.MuiStepConnector-vertical {
  .MuiStepConnector-lineVertical {
    height: 100px;
    border-left-width: 2px;
  }
}

svg.Mui-active {
  //border: 5px solid cornflowerblue;
  color: #CBD1E1 !important;
}

.MuiStepConnector-vertical.MuiStepConnector-root {
  margin-left: 14px;
}

.MuiStepConnector-vertical.Mui-disabled {
  span {
    border-color: #EDEDF3;
  }
}

.stepper-wrapper {
  margin-top: 10px;
}


