.header {
    background-color: #ffffff;
    padding: 0.5rem 1.5rem;
    height: 6rem;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #EDEFF2;
}

.logout {
    margin-left: auto;
}

.logo {
    height: 3.2rem;
}

.menu-icon-btn {
    border: none;
    background: none;
    padding: 0;
    padding-top: 1.5rem;
}
.home-btn {
    border: none;
    background: none;
}

.menu-icon {
    color: #C3CAD9;
    cursor: pointer;
}

.menu-icon:hover {
    color: #C3CAD9;
}

.my-docs {
    font-weight: 600;
    font-size: 13px;
    line-height: 25px;
    color: #6B7A99;
    text-decoration: none;
    margin-left: 5px;
}

.dropdown-main .btn, .dropdown-main .btn:hover, .dropdown-main .btn:active, .dropdown-main .btn:focus {
    background-color: transparent;
    border: 0;
}
.dropdown-option {
    width: 150px;
    height: 40px;
    font-size: 12px;
    padding-top: 10px;
}
.dropdown-option i {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 14px;
}