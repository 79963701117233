@import "src/assets/static/styles/spec/settings/fonts";

.table-wrapper {
  width: 100%;
  font-size: 1.2rem !important;

  .MuiTableCell-root, .MuiTableCell-head, .css-1w1rijm-MuiButtonBase-root-MuiButton-root, .MuiTypography-root {
    font-size: 1.2rem !important;
    font-family: $font-primary;
  }

  .MuiPaper-root {
    font-size: 1.2rem !important;
    padding: 0 1rem;
  }
}