#profile {
  color: #495472;
  padding: 20px;

  .profile-header {
    display: flex;
    justify-content: space-between;
  }

  .profile-wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows:  repeat(2, 120px) 60px;
    margin-top: 15px;

    .box {
      background: #FFFFFF;
      box-shadow: 0 2px 8px rgba(38, 31, 43, 0.08);
      border-radius: 8px;
      padding: 20px;
      font-size: 150%;
    }

    .profile-details-box {
      grid-column: 1;
      grid-row: 1 / 3;
      padding: 30px 20px;
      display: grid;
      grid-template-columns: 200px 1fr;

      .profile-details-text {
        .profile-name {
          font-weight: 500;
          font-size: 18px;
          color: #495472;
        }

        .profile-email {
          font-weight: 500;
          font-size: 12px;
          color: #7D8FB3;
        }

        .username-input {
          padding: 10px;
          width: 80%;
        }

        .btn {
          padding: 10px;
        }

        .save-btn {
          background: #072233;
          color: #FFFFFF;
        }

        .username-label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #726B76;
        }

      }
    }

    .address-box {
      grid-column: 2;
      grid-row: 1 / 3;
      padding: 30px 20px;

      p {
        font-weight: 500;
        font-size: 12px;
        color: #7D8FB3;
      }

      .address-input {
        min-height: 120px;
      }

      .btn {
        padding: 10px;
      }

      .save-btn {
        background: #072233;
        color: #FFFFFF;
      }

      .username-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #726B76;
      }
    }

    .team-mates-box {
      grid-column: 3;
      grid-row: 1 / 4;
      background: #E0102A;
      color: #FFFFFF;

      .team-mate-total {
        font-weight: 500;
        font-size: 16px;
      }

      .team-mate-body {
        display: flex;

        .team-mate-body-img {
          width: 30%;
          text-align: right;
          padding-right: 15px;
          font-size: 100px;
        }

        .team-mate-body-desc {
          padding-top: 35px;
        }
      }

      .team-mate-footer {
        display: flex;
        justify-content: space-between;
        margin: 40px 30px;
      }
    }

    .change-password-box {
      grid-column: 1 / 3;
      grid-row: 3;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #261F2B;
    }
  }

  .btn {
    width: 100px !important;
    background: #FFFFFF;
    box-shadow: 0 2px 8px rgba(38, 31, 43, 0.08);
    border-radius: 8px;
    text-align: center;
    color: #202B40;
  }
}

@media screen and (max-width: 999px) {
  #profile {
    .profile-wrapper {
      grid-template-columns: 2fr 1fr;
      grid-template-rows:  repeat(2, 120px) 60px repeat(2, 120px);

      .team-mates-box {
        grid-column: 1 / 3;
        grid-row: 4 / 6;
      }

      .address-box {
        grid-column: 2 / 3;
      }

      .change-password-box {
        grid-column: 1 / 3;
      }
    }
  }
}

@media screen and (max-width: 799px) {
  #profile {
    .profile-wrapper {
      grid-template-rows:  repeat(2, 140px) 60px repeat(2, 140px);
    }
  }
}
