@import "src/assets/static/styles/spec/tools/mixins/mediaQueriesRanges";
@import "src/assets/static/styles/spec/settings/breakpoints";

.team-logo-wrapper {
  display: flex;
  align-items: center;

  .team-logo {
    img {
      width: 130px;
    }
  }
}

.team-summary-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 180px minmax(280px, 3fr) minmax(400px, 4fr);
  grid-auto-rows: minmax(150px, auto);
}

@include to($breakpoint-md) {
  .team-summary-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 200px 3fr;
    margin: 1.5rem 0;
  }
  .team-summary-roles {
    grid-column: 1 / 4;
  }
}

.team-summary-item {
  padding: 20px;
  background: #FFFFFF;
  border-radius: 12px;

  .team-summary-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #495472;
    border-bottom: 1px solid beige;
    padding-bottom: 7px;
  }

  .team-summary {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }

  .individual-item-header {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #495472;
    margin-top: 10px;
  }

  .individual-item-value {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #45464E;
  }

  .role-summary {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }
}

.add-team-member-modal {
  min-width: 600px;

  .add-team-modal {
    .invite-header {
      font-weight: 500;
      font-size: 26px;
      text-align: center;
      color: #261F2B;
      margin-top: 30px;
    }

    .invite-desc {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #6B7A99;
      max-width: 375px;
      margin: auto;
    }

    .add-team-member-email {
      width: 85%;
      margin: 20px auto 0;
      background: #FFFFFF;
      border: 1px solid #F3F1FB;
      box-shadow: 0 2px 8px rgba(38, 31, 43, 0.08);
      border-radius: 21px;

      .add-team-member-email-input.react-select__control {
        border-radius: 21px;

        .add-team-member-email-input.react-select__multi-value {
          background: #E0102A;
          border-radius: 21px;
          color: #FFFFFF;
          font-weight: 400;
          font-size: 14px;
          padding: 5px;
        }

        .add-team-member-email-input.react-select__multi-value__label {
          color: #FFFFFF;
        }
      }
    }

    .add-team-member-error-message {
      width: 85%;
      margin: auto;
      color: red;
      padding-left: 10px;
      font-size: 12px;
    }

    .add-team-member-type {
      width: 85%;
      margin: 15px auto 25px;
      font-weight: 500;
      font-size: 14px;
      color: #261F2B;

      .form-check {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .modal-footer {
    padding: 15px;
  }
}
